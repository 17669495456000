var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field"},[(_vm.showCodeFragmentMark)?_c('span',[_vm._v("[cfm: components/VoterLeo.vue - begin]")]):_vm._e(),_vm._v(" "),_c('field-label-and-tooltip',{attrs:{"fieldName":_vm.fieldName,"label":_vm.label,"instructions":_vm.instructions,"toolTipTitle":_vm.toolTipTitle,"toolTipContent":_vm.toolTipContent,"isToolTipOpen":_vm.isToolTipOpen},on:{"toggleIsToolTipOpen":_vm.toggleIsToolTipOpen}}),_vm._v(" "),(_vm.loading)?_c('b-field',[_c('At',{attrs:{"s":_vm.dict.F13}})],1):_c('b-field',[_c('b-field',[_c('button',{ref:"jbutton",staticClass:"button is-grey is-outlined",on:{"click":function($event){$event.preventDefault();{
          if (_vm.isOpen_Jurisdiction) {
            _vm.isInFocus_Jurisdiction = false
            _vm.isOpen_Jurisdiction = false
          } else {
            _vm.$refs.jurisdiction.focus({focusVisible: true})
            _vm.isInFocus_Jurisdiction = true
            _vm.isOpen_Jurisdiction = true
          }
        }}}},[(_vm.isInFocus_Jurisdiction)?_c('b-icon',{attrs:{"pack":"fas","icon":"caret-down","alt":"Click to close the drop down list of local election officials"}}):_c('b-icon',{attrs:{"pack":"fas","icon":"caret-right","alt":"Click to open the drop down list of local election officials"}})],1),_vm._v(" "),_c('b-autocomplete',{ref:"jurisdiction",attrs:{"value":_vm.typedJurisdiction || _vm.jurisdiction || '',"open-on-focus":"","keep-first":"","expanded":"","data":_vm.filteredLeos,"field":"n","placeholder":_vm.placeholder},on:{"input":function (val) {
            _vm.typedJurisdiction = val
            /**
            2022-09-04 John Yee:
            updated() causes an error when voter selects a LEO from the drop down list
            '500 handler.apply is not a function'
            Commenting out the line does not appear to cause any subsequent errors.
            */
            // updated();
          },"select":function (option) {_vm.isInFocus_Jurisdiction = false; _vm.updateLeo(option)},"blur":function($event){_vm.isInFocus_Jurisdiction = false},"focus":function($event){_vm.isInFocus_Jurisdiction = true}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b',[_vm._v("\n            "+_vm._s(props.option.j)+"\n            "+_vm._s(props.option.j
                .toLowerCase()
                .indexOf(props.option.t.toLowerCase()) > -1
                ? ""
                : props.option.t)+"\n          ")]),_vm._v("\n          - "),_c('small',[_vm._v(_vm._s(_vm.decodeHtmlEntity(props.option.n))+" ")])]}}])},[_c('template',{slot:"empty"},[_vm._v("\n          "+_vm._s(_vm.getDictWP(_vm.dict.E08, { typed : _vm.typedJurisdiction || _vm.jurisdiction }))+"\n          "),_c('a',{staticClass:"button is-primary is-small",on:{"click":function () {
                _vm.typedJurisdiction = '';
                _vm.$refs.jurisdiction.focus();
              }}},[_c('At',{attrs:{"s":_vm.dict.D42}})],1)])],2)],1)],1),_vm._v(" "),(Object.keys(_vm.selectedLeo).length>0 && _vm.state===_vm.selectedLeo.s)?_c('div',{staticClass:"box boldprimary"},[_c('span',[_vm._v(_vm._s(_vm.selectedLeo.n)),_c('br')]),_vm._v(" "),(_vm.selectedLeo.a1)?_c('span',[_vm._v(_vm._s(_vm.selectedLeo.a1)),_c('br')]):_vm._e(),_vm._v(" "),(_vm.selectedLeo.a2)?_c('span',[_vm._v(_vm._s(_vm.selectedLeo.a2)),_c('br')]):_vm._e(),_vm._v(" "),(_vm.selectedLeo.a3)?_c('span',[_vm._v(_vm._s(_vm.selectedLeo.a3)),_c('br')]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n      "+_vm._s(_vm.selectedLeo.c)+", \n      "+_vm._s(_vm.selectedLeo.s)+"\n      "+_vm._s(_vm.selectedLeo.z)),_c('br')]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.selectedLeo.e)?_c('span',[_vm._v("Email: "+_vm._s(_vm.selectedLeo.e)),_c('br')]):_vm._e(),_vm._v(" "),(_vm.selectedLeo.p)?_c('span',[_vm._v("Tel: +1 "+_vm._s(_vm.selectedLeo.p)),_c('br')]):_vm._e(),_vm._v(" "),(_vm.selectedLeo.f)?_c('span',[_vm._v("Fax: +1 "+_vm._s(_vm.selectedLeo.f)),_c('br')]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.showCodeFragmentMark)?_c('span',[_vm._v("[cfm: components/VoterLeo.vue - end]")]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("United States of America"),_c('br')])}]

export { render, staticRenderFns }